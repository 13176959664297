<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
      class="pl-0 pr-0 pt-0"
      style="max-width: 600px;"
    >
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text class="pt-1 pb-1">
              <div class="d-flex justify-space-between align-center">
                <span>
                  Promotor: {{ revenda.parent_name }}
                </span>

                <v-btn
                  text
                  @click="voltar()"
                >
                  VOLTAR
                </v-btn>

              </div>
            </v-card-text>
          </v-card>

        </v-col>

        <v-col cols="12" class="pt-0">
          <v-card 
            :color="variables.colorPrimaryGecom"
            elevation="0"
            rounded="0"
            class="box-saldo"
            dark
          >
            <v-card-title
              class="box-saldo-titulo"
            >
              Revenda {{ revenda.entity_cod }}
              <span class="date d-block mb-0">{{ this.date | datas('DD/MM/YYYY') }}</span>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="6" class="pt-0 pr-1">
          <v-card 
            :color="revenda.deve_ant <= 0 ? variables.colorPrimaryGecom : variables.colorPrimary"
            elevation="0"
            rounded="0"
            class="box-saldo"
            :class="{'is-hidden': revenda.deve_ant > 0}"
          >
            <v-card-text
              class="box-saldo__content"
            >
              <span>Saldo Anterior</span>
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
              >
                <strong>{{ revenda.deve_ant | currency }}</strong>
              </v-skeleton-loader>
            </v-card-text>
          </v-card>

          <v-card 
            :color="variables.colorPrimaryGecom"
            elevation="3"
            rounded="1"
            class="box-saldo mt-2"
            @click="openModalTransmissao()"
          >
            <v-card-text
              class="box-saldo__content"
            >
              <span>Vendas</span>
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
              >
                <strong>{{ revenda.proposta | currency }}</strong>
              </v-skeleton-loader>
            </v-card-text>
          </v-card>

          <v-card 
            :color="variables.colorPrimaryGecom"
            elevation="0"
            rounded="0"
            class="box-saldo mt-2"
          >
            <v-card-text
              class="box-saldo__content"
            >
              <span>Saldo Atual</span>
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
              >
                <strong>{{ revenda.deve_atual | currency }}</strong>
              </v-skeleton-loader>
            </v-card-text>
          </v-card>

          <v-card 
            :color="revenda.deve_pending <= 0 ? variables.colorPrimaryGecom : variables.colorPositiveGecom"
            elevation="0"
            rounded="0"
            class="box-saldo mt-2"
            dark
          >
            <v-card-text
              class="box-saldo__content"
            >
              <span>SALDO PREVISTO</span>
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
              >
                <strong>{{ revenda.deve_pending | currency }}</strong>
              </v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" class="pt-0 pl-1">
          <v-card 
            :color="revenda.deve_ant > 0 ? variables.colorPositiveGecom : variables.colorPrimaryGecom"
            elevation="0"
            rounded="0"
            class="box-saldo"
            :class="{'is-hidden': revenda.deve_ant <= 0}"
          >
            <v-card-text
              class="box-saldo__content"
            >
              <span>Saldo Anterior</span>
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
              >
                <strong>{{ revenda.deve_ant | currency }}</strong>
              </v-skeleton-loader>
            </v-card-text>
          </v-card>

          <v-card 
            :color="now ? variables.colorPrimary : variables.colorPrimaryGecom"
            :elevation="now ? 3 : 0"
            rounded="1"
            class="box-saldo mt-2"
            @click="openModalPremiosPrevisto()"
          >
            <v-card-text
              class="box-saldo__content"
            >
              <span>Pr. Previsto</span>
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
              >
                <strong>{{ revenda.pending_prize | currency }}</strong>
              </v-skeleton-loader>
            </v-card-text>
          </v-card>

          <v-card 
            :color="variables.colorPrimary"
            elevation="3"
            rounded="1"
            class="box-saldo mt-2"
            @click="openModalPremiosPagos()"
          >
            <v-card-text
              class="box-saldo__content"
            >
              <span>Prêmios pagos</span>
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
              >
                <strong>{{ revenda.premios_pagos | currency }}</strong>
              </v-skeleton-loader>
            </v-card-text>
          </v-card>

          <v-card 
            :color="revenda.movimento < 0 ? variables.colorPrimaryGecom : variables.colorPrimary"
            elevation="3"
            rounded="1"
            class="box-saldo mt-2"
            @click="openModalMovimentos()"
          >
            <v-card-text
              class="box-saldo__content"
            >
              <span>Movimento</span>
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
              >
                <strong>{{ revenda.movimento | currency }}</strong>
              </v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-expansion-panels v-model="isExpanded">
            <v-expansion-panel>
              <v-expansion-panel-header class="pt-2 pb-2">
                <v-row>
                  <v-col>
                    Mais informações +
                  </v-col>
                </v-row>
                <!-- {{ date | datas('DD/MM/YYYY') }} - {{ entityStart.value }} - {{ entityEnd.value }} -->
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <TableRevenda :dataTable="revenda" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <ModalTransmissao />
    <ModalJogosWinners />
    <ModalMovimento />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'
import Events from '@/core/service/events'
import moment from 'moment'

export default {
  name: 'GefinDadosRevenda',
  components: {
    // autoCompleteEntities: () => import('../../components/autoCompleteEntities/gecom'),
    ModalTransmissao: () => import('../../components/modalTransmissoes'),
    TableRevenda: () => import('../../components/tableRevenda'),
    ModalMovimento: () => import('../../components/modalMovimento'),
    ModalJogosWinners: () => import('@/views/components/tabelaHistoricoVendas/components/modalJogosWinners'),
  },
  data: () => ({
    date: '',
    isExpanded: null,
    loading: false
  }),

  watch: {

  },

  mounted () {
    if (this.$route.params.entity) {
      this.date = this.$route.params.date
      this.setDataRevenda(this.$route.params.entity)
    } else {
      this.$router.push({ name: 'gefin-mobile' })
    }

  },

  computed: {
    ...mapGetters('relatorioGefinDadosRevenda', ['revenda', 'listaItens', 'configGecom', 'totais']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('credenciador', ['item']),
    variables: () => variables,
    now () {
      return moment().format('YYYY-MM-DD') === this.date
    }
  },

  methods: {
    ...mapActions('relatorioGefinDadosRevenda', ['setDataRevenda']),
    // ...mapActions('credenciador', {
    //   getPromotor: 'getItens'
    // }),

    openModalTransmissao () {
       const data = {
        entityId: this.revenda.entity_id,
        entity_cod: this.revenda.entity_cod,
        dtStart: this.date,
        dtEnd: this.date,
        hasMonthOrDate: 1
      }

      Events.$emit('modal::historicoVendas::transmissoes', data)
    },

    openModalPremiosPagos () {
       const entity = {
        entity_id: this.revenda.entity_id,
        entity_cod: this.revenda.entity_cod
      }

      Events.$emit('modal::historicoVendas::jogosWinners', { data: this.date, entity, hasMonthOrDate: 1 })
    },

    openModalPremiosPrevisto () {
      if (!this.now) return

      const entity = {
        entity_id: this.revenda.entity_id,
        entity_cod: this.revenda.entity_cod
      }

      Events.$emit('modal::historicoVendas::jogosWinners', { data: this.date, entity, hasMonthOrDate: 1, pending: true })
    },

    openModalMovimentos () {
       const entity = {
        entity_id: this.revenda.entity_id,
        entity_cod: this.revenda.entity_cod
      }

      Events.$emit('modal::historicoVendas::movimentos', { data: this.date, entity, hasMonthOrDate: 1, pending: true })
    },

    voltar () {
      const parentPathArray = this.revenda.parent_path.split('.')
      parentPathArray.pop()
      
      this.$router.push({ name: 'gefin-mobile', params: { parentPath: parentPathArray.join('.') } })
    }
  },

  beforeDestroy () {}
}
</script>

<style lang="scss" scoped>
  .box-saldo {
    color: #FFF;
    &-titulo {
      text-align: center;
      font-size: .9rem;
      width: 100%;
      display: block;
      padding-top: .4rem;
    }

    &__content {
      text-align: center;
     span {
       color: #Fff;
       display: block;
     }

     strong {
       color: #Fff;
       font-size: 1.3rem;
     }
    }

    .date {
      font-size: 12px;
      line-height: 1;
      margin-bottom: 10px;
    }
  }

  .is-hidden {
    visibility: hidden;
  }
</style>>
